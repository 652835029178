<template>
    <div>
        <TopCardMenu @refreshAll="getAll" />

        <div class="flex">
            <InvoicesTable
                :invoicesData="invoicesData"
                @toggleDetails="toggleDetails"
                @showInvoiceDetails="changeInvoiceDetails"
                @refresh="getAll"
            />

            <InvoiceDetails
                :visible="visibleDetailsComponent"
                :invoiceUuid="selectedInvoiceUuid"
                @refresh="getAll"
            />
        </div>
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        InvoiceDetails: () => import(/* webpackChunkName: "Invoices/InvoiceDetails" */ './components/InvoiceDetails'),
        TopCardMenu:    () => import(/* webpackChunkName: "Invoices/TopCardMenu" */ './components/TopCardMenu'),
        InvoicesTable:  () => import(/* webpackChunkName: "Invoices/InvoicesTable" */ './components/InvoicesTable'),
    },

    data() {
        return {
            visibleDetailsComponent: false,
            selectedInvoiceUuid:     null,
            invoicesData:            [],
            invoicesNumbers:         [],
        };
    },

    beforeCreate() {
        this.$store.commit('economy/toggleDetailsView', false);
        this.$wait.start('loading.initial_invoices');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('economy.invoices'));
        this.$store.commit('setActiveMenuItem', '6-1');
        this.getAll();
    },

    methods: {
        toggleDetails(uuid = null) {
            this.visibleDetailsComponent = !this.visibleDetailsComponent;
            this.selectedInvoiceUuid = uuid;
        },

        changeInvoiceDetails(uuid) {
            this.selectedInvoiceUuid = uuid;
        },

        async getAll() {
            this.$wait.start('loading');
            const query = queryString.parse(window.location.search, { sort: false });

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices`, {
                params: {
                    from:   query.from ? query.from : this.$dayjs().startOf('month').unix(),
                    to:     query.to ? query.to : this.$dayjs().endOf('month').unix(),
                    status: query.status ? query.status : '',
                    type:   query.type ? query.type : '',
                    client: query.client,
                },
            });

            this.invoicesData = Object.freeze(data.invoices);
            this.$store.commit('economy/updateInvoicesNumbers', data.numbers);
            this.$store.commit('economy/updateInvoicesNumbersByType', data.numbers_by_type);
            this.$wait.end('loading');
            this.$wait.end('loading_date_range.prev');
            this.$wait.end('loading_date_range.next');
            this.$wait.end('loading.initial_invoices');
        },
    },
};
</script>
<style>
.el-drawer__wrapper {
    left: 410px;
}
</style>
